import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen40 } from '../../../components/images/support/users/Screen40.generated';
import { Screen41 } from '../../../components/images/support/users/Screen41.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invite-a-member-️"
    }}>{`Invite a Member 🧑‍🍳👨🏾‍💼👷‍♀️`}</h1>
    <p>{`This section shows how to invite a member to Canyou. `}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the users menu and click `}<strong parentName="li">{`Add user`}</strong>{`.`}</li>
    </ol>

    <Screen40 mdxType="Screen40" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Add the members basic information and click `}<strong parentName="li">{`Invite`}</strong>{`.`}</li>
    </ol>
    <p>{`This will send an email to the member inviting them to Canyou.`}</p>
    <p>{`When the member accepts the invite they will be prompted to complete any Workflows they have been assigned.`}</p>

    <Screen41 mdxType="Screen41" />
    <h3 {...{
      "id": "learn-how-to-invite-an-admin-user"
    }}>{`Learn how to invite an admin user`}</h3>

    <SupportFooter linkTo="/support/users/invite-an-admin" linkText="Invite an admin user" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      